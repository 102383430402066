.line {
    border: 1px solid black;
    margin: 1rem 0;
}

.redNotice {
    color: red;
}

.rs-table {
    overflow: auto;
}

.rs-modal-body {
    overflow: auto;
}