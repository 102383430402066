.salaryInputHidden {
    height: 33px;
    border: 0;
    padding-left: 6px;
    font-size: 16px;
    text-align: center;
}

.salaryInput {
    height: 33px;
    // border: 0;
    padding-left: 6px;
    font-size: 14px;
    border-radius: 5px;
}

.salaryInputNotHidden {
    height: 37px;
    border: 1px solid #E5E5EA;
    margin-top: -2px;
    // border: 0;
    padding-left: 6px;
    font-size: 14px;
    border-radius: 5px;
}

.container {
    padding-top: 4px;
    font-size: 15px;
}

.showText {
    color: #3498ff;
    cursor: pointer;
}

.labelText {
    font-size: 15px;
    font-weight: 500;
}