.containerPassword {
    width: 100%;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3rem;
}

.containerLogo {
    display: flex;
    justify-content: center;
}

.logoPassword {
    display: flex;
    justify-content: center;
    width: 100%;
}

.subcontainer {
    display: flex;
    width: 90%;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
}

.btnSend {
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
}

.height-50 {
    height: 50px;
}

.containner-btn {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.font-center {
    font-weight: 800;
    text-align: center;
    font-size: 20pt;
}

.invalidField {
    box-shadow: 0 0 5px 2px red;
    transition: box-shadow 0.3s ease-in-out;
}