* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
  
.header {
    width: 100%;
    height: 6dvh;
    background-color: #00A0BD;
    padding: 0 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.userName {
    font-size: 1rem;
    color: #fff;
}

.arrow {
    width: 32px;
    height: 32px;
    color: #fff;
}

.mainContainer {
    width: 90%;
    margin: 0 auto;
    margin-top: 1rem;
}

.headerButtonsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.includeButton {
    width: 100%;
}

.checklistsContainer {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.cardText {
    display: flex;
    flex-direction: column;
    gap: .3rem;
}

.returnPageText {
    color: #FFF;
    margin-left: -.5rem;
}

.buttonReturnPage {
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
