.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(35, 27, 58, 0.887);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.fullscreen-overlay-content {
    text-align: center;
    color: #fff;

}

.fullscreen-overlay-icon {
    font-size: 50px;
    color: #3498db;
}