/* styles.css */

.container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    gap: 1rem;
    
}

.tablename {
    margin-top: -20px;
    font-size: 16pt;
    font-weight: 600;
}

.title {
    font-size: 24pt;
    color: #333;
    font-weight: 700;
    
}

.container h2 {
    margin-bottom: 20px;
    color: #333;
}

.question {
    border: 1px solid #ccc;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 6px;
}

.question p {
    margin: 5px 0;
}

.question img {
    border-radius: 5px;
    width: 200px;
    height: auto;
    object-fit: cover;
    margin-right: 10px;
}

.question a {
    margin-left: 10px;
    color: #007bff;
    text-decoration: none;
}

.signatureContainer {
    margin-top: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signatureContainer h3 {
    margin-bottom: 10px;
}

.signatureImage {
    width: 150px;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    transform: rotate(-90deg);
    margin: -5rem 0;
}

.generatePDFButton {
    margin-top: 10px;
}

.modalContent {
    padding: 20px;
    color: #333;
}