.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    // justify-content: center;
    align-items: center;
}

.submitContainer {
    display: flex;
    justify-content: center;
}

.containerLGPDText {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 1rem;
}

.containerLGPDText h3 {
    font-size: 1.3rem;
}

.containerLGPDText span {
    text-align: justify;
}

ul {
    list-style: none;
    text-align: justify;

}

ul li {
    text-align: justify;
}

// .rsFormControlWrapper {

// }

// .checkbox {
//     display: flex;
//     margin: 0 auto;
//     align-items: center;
//     justify-content: center;
// }

.checkboxContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

// .label {
//     text-align: center;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// }

.fileControlLabel {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .container {
        width: 100%!important;
    }

    .formContainer {
        flex-direction: column;
    }

    .form {
        flex-direction: column;
        gap: 10px;
        width: 100%
    }

    .form input {
        width: 100%;
    }

    ul {
        margin-left: -38px;
    }

    .rs-form-control-wrapper {
        flex-direction: column;
    }

    .fileControlLabel {
        flex-direction: column;
    }

    .submitContainer {
        justify-content: center;
    }
}