.containner-button {
    margin-top: 10px;
    width: 80%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.counter {
    color: rgb(137, 136, 138);
    font-size: 8pt;
}

.margin-10 {
    margin-top: 10px;
}
