@import url("https://pro.fontawesome.com/releases/v5.10.0/css/all.css");

.allow-overflow {
  overflow: visible !important;
}

.p-rel {
  position: relative;
}

/* #editor {
  max-width: 500px   !important;
} */

div.rs-picker-select-menu-items {
  max-height: 200px !important;
}

/* div.rs-panel {
  max-height: 100vh !important;
}

div.rs-modal-content .rs-uploader-trigger {
  background: red !important;
  max-width: 730px !important;
  padding: 5px !important

} */

.p-abs {
  position: absolute !important;
}

.h-100-body .rs-panel-body {
  height: 100%;
}

.h-100 {
  height: 100%;
}

.rs-loader-lg .rs-loader {
  height: 64px;
}

.favorites .favorite-item {
  border: 1px solid #e5e5ea;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.favorites .favorite-item .icon,
.favorites .favorite-item .title {
  color: #333;
  z-index: 1;
  transition: 0.2s all;
}

.favorites .favorite-item .icon {
  font-size: 28px;
}

.favorites .favorite-item .title {
  font-size: 16px;
  text-align: center;
}

.favorites .favorite-item:hover {
  cursor: pointer;
}

.favorites .favorite-item:hover .bar {
  height: 100%;
}

.favorites .favorite-item:hover .icon,
.favorites .favorite-item:hover .title {
  color: white;
}

.favorites .favorite-item .bar {
  transition: 0.2s all;
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: 0%;
  z-index: 0;
}

.clock-in-psm .header * {
  font-weight: bold;
}

.clock-in-psm .rs-flex-box-grid-item.rs-flex-box-grid-item-9 div span {
  font-weight: bold;
  opacity: 0.9;
}

#editorContainer {
  width: 100% !important;
  /*max-width: calc(100vw - 320px);*/
  max-width: calc(100vw - 140px);
}

.file-upload-input {

  max-width: calc(100vw - 340px);
}

.sidebar {
  position: fixed;
  left: 3px;
  top: 0;
  width: 200px;
  background: #fff;
  height: 100vh;
  z-index: 499;
  transition: 0.2s width;
  padding-top: 5px;
  box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.sidebar.sidebar-hidden {
  width: 0;
  overflow: hidden;
}

.sidebar .sidebar-search-bar {
  padding: 10px;
}

.sidebar .item-list {
  height: calc(100% - 60px);
  overflow: auto;
  padding-bottom: 55px;
}

.sidebar .logo-2 {
  width: 120%;
  height: 39px;
  top: -5px;
  position: relative;
  background: #0096a0;
  background: linear-gradient(166deg, #0096a0 0%, #005664 100%);
  border-radius: 0px 0px 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 22px;
  color: white;
}

.sidebar .logo-2 i {
  position: relative;
  left: -20px;
}

.sidebar .logo {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 5px 15px;
}

.sidebar .logo .text {
  font-size: 23px;
  margin-left: 10px;
  color: #656469;
  font-weight: bold;
}

.sidebar .logo .spot {
  white-space: nowrap;
  width: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  font-size: 23px;
  color: white;
  font-weight: bold;
  border-radius: 10px 3px 10px 3px;
}

.sidebar img {
  width: 100%;
  max-height: 45px;
  object-fit: contain;
}

.sidebar .is-loading {
  padding: 10px;
}

.sidebar .item.active .item-rendered {
  background: #d9eff1;
  cursor: pointer;
}

.sidebar .item.active .item-rendered::before {
  height: 100%;
  background: #0096a0;
}

.sidebar .item.active .item-rendered:hover {
  background: #c6e7ea !important;
}

.sidebar .item .item-rendered {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out all;
  position: relative;
  min-height: 40px;
  width: 100%;
}

.sidebar .item .item-rendered::before {
  content: "";
  height: 0%;
  width: 4px;
  background: white;
  position: absolute;
  border-radius: 2px;
  opacity: 0.9;
  transition: 0.2s ease-in-out all;
}

.sidebar .item .item-rendered .icon {
  left: 15px;
  position: relative;
  color: #858585;
  width: 15px;
}

.sidebar .item .item-rendered .text {
  color: #858585;
  font-weight: bold;
  position: relative;
  left: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 70%;
}

.sidebar .item .item-rendered .chevron {
  position: relative;
  margin-left: auto;
  right: 10px;
  color: #858585;
}

.sidebar .item .item-rendered:hover,
.sidebar .item .item-rendered.active {
  background: #d9eff1;
  cursor: pointer;
}

.sidebar .item .item-rendered:hover::before,
.sidebar .item .item-rendered.active::before {
  height: 100%;
  background: #0096a0;
}

.sidebar .item .item-rendered.active:hover {
  background: #c6e7ea !important;
}

.sidebar .item .item-rendered:active {
  background: #d9eff1;
  cursor: pointer;
}

.sidebar .item .item-rendered:last-child {
  margin-bottom: 0;
}

.sidebar .item .item-rendered::after {
  content: "";
  position: absolute;
  left: 5%;
  right: 5%;
  height: 3px;
  border-radius: 3px;
  bottom: -4px;
  background: rgba(0, 0, 0, 0.05);
}

.container {
  padding: 0px 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

}

.container:not(.grid) {
  margin-top: 65px;
}

/* @media (min-width: 800px){
  .container:not(.grid) {
    margin-left: 203px;
    margin-top: 65px;
  }
} */


.container .row {
  width: 100%;
  margin-left: -0.6%;
  margin-right: -0.6%;
  display: flex;
  flex-wrap: wrap;
}

.container .row.no-margin {
  margin-left: -5px;
  margin-right: -5px;
}

.container * {
  box-sizing: border-box;
}

.container .grid [class*=g-],
.container .grid [class*=col-] {
  margin-bottom: 0px;
}

.container .g-1,
.container .col-1,
.container .col-md-1 {
  width: 8.3333333333%;
  float: left;
  padding: 0 0.3%;
  margin-bottom: 0.2%;
}

.container .g-1 .input,
.container .col-1 .input,
.container .col-md-1 .input {
  padding: 0 0.6%;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .container .col-sm-1 {
    width: 8.3333333333%;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .container .col-xs-1 {
    width: 8.3333333333%;
  }
}

.container .g-2,
.container .col-2,
.container .col-md-2 {
  width: 16.6666666667%;
  float: left;
  padding: 0 0.3%;
  margin-bottom: 0.2%;
}

.container .g-2 .input,
.container .col-2 .input,
.container .col-md-2 .input {
  padding: 0 0.6%;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .container .col-sm-2 {
    width: 16.6666666667%;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .container .col-xs-2 {
    width: 16.6666666667%;
  }
}

.container .g-3,
.container .col-3,
.container .col-md-3 {
  width: 25%;
  float: left;
  padding: 0 0.3%;
  margin-bottom: 0.2%;
}

.container .g-3 .input,
.container .col-3 .input,
.container .col-md-3 .input {
  padding: 0 0.6%;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .container .col-sm-3 {
    width: 25%;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .container .col-xs-3 {
    width: 25%;
  }
}

.container .g-4,
.container .col-4,
.container .col-md-4 {
  width: 33.3333333333%;
  float: left;
  padding: 0 0.3%;
  margin-bottom: 0.2%;
}

.container .g-4 .input,
.container .col-4 .input,
.container .col-md-4 .input {
  padding: 0 0.6%;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .container .col-sm-4 {
    width: 33.3333333333%;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .container .col-xs-4 {
    width: 33.3333333333%;
  }
}

.container .g-5,
.container .col-5,
.container .col-md-5 {
  width: 41.6666666667%;
  float: left;
  padding: 0 0.3%;
  margin-bottom: 0.2%;
}

.container .g-5 .input,
.container .col-5 .input,
.container .col-md-5 .input {
  padding: 0 0.6%;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .container .col-sm-5 {
    width: 41.6666666667%;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .container .col-xs-5 {
    width: 41.6666666667%;
  }
}

.container .g-6,
.container .col-6,
.container .col-md-6 {
  width: 50%;
  float: left;
  padding: 0 0.3%;
  margin-bottom: 0.2%;
}

.container .g-6 .input,
.container .col-6 .input,
.container .col-md-6 .input {
  padding: 0 0.6%;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .container .col-sm-6 {
    width: 50%;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .container .col-xs-6 {
    width: 50%;
  }
}

.container .g-7,
.container .col-7,
.container .col-md-7 {
  width: 58.3333333333%;
  float: left;
  padding: 0 0.3%;
  margin-bottom: 0.2%;
}

.container .g-7 .input,
.container .col-7 .input,
.container .col-md-7 .input {
  padding: 0 0.6%;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .container .col-sm-7 {
    width: 58.3333333333%;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .container .col-xs-7 {
    width: 58.3333333333%;
  }
}

.container .g-8,
.container .col-8,
.container .col-md-8 {
  width: 66.6666666667%;
  float: left;
  padding: 0 0.3%;
  margin-bottom: 0.2%;
}

.container .g-8 .input,
.container .col-8 .input,
.container .col-md-8 .input {
  padding: 0 0.6%;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .container .col-sm-8 {
    width: 66.6666666667%;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .container .col-xs-8 {
    width: 66.6666666667%;
  }
}

.container .g-9,
.container .col-9,
.container .col-md-9 {
  width: 75%;
  float: left;
  padding: 0 0.3%;
  margin-bottom: 0.2%;
}

.container .g-9 .input,
.container .col-9 .input,
.container .col-md-9 .input {
  padding: 0 0.6%;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .container .col-sm-9 {
    width: 75%;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .container .col-xs-9 {
    width: 75%;
  }
}

.container .g-10,
.container .col-10,
.container .col-md-10 {
  width: 83.3333333333%;
  float: left;
  padding: 0 0.3%;
  margin-bottom: 0.2%;
}

.container .g-10 .input,
.container .col-10 .input,
.container .col-md-10 .input {
  padding: 0 0.6%;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .container .col-sm-10 {
    width: 83.3333333333%;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .container .col-xs-10 {
    width: 83.3333333333%;
  }
}

.container .g-11,
.container .col-11,
.container .col-md-11 {
  width: 91.6666666667%;
  float: left;
  padding: 0 0.3%;
  margin-bottom: 0.2%;
}

.container .g-11 .input,
.container .col-11 .input,
.container .col-md-11 .input {
  padding: 0 0.6%;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .container .col-sm-11 {
    width: 91.6666666667%;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .container .col-xs-11 {
    width: 91.6666666667%;
  }
}

.container .g-12,
.container .col-12,
.container .col-md-12 {
  width: 100%;
  float: left;
  padding: 0 0.3%;
  margin-bottom: 0.2%;
}

.container .g-12 .input,
.container .col-12 .input,
.container .col-md-12 .input {
  padding: 0 0.6%;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .container .col-sm-12 {
    width: 100%;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .container .col-xs-12 {
    width: 100%;
  }
}

.calendar-wrapper {
  padding-left: 1px;
  padding-top: 1px;
  overflow: hidden;
}

.calendar-wrapper .calendar-headers {
  display: flex;
}

.calendar-wrapper .calendar-headers .calendar-header {
  width: 14.2857142857%;
  text-align: center;
  height: 32px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5ea;
  margin-left: -1px;
  margin-top: -1px;
}

.calendar-wrapper .calendar-week {
  display: flex;
}

.calendar-wrapper .calendar-week .calendar-day {
  width: 14.2857142857%;
  text-align: center;
  height: 42px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5ea;
  margin-left: -1px;
  margin-top: -1px;
}

.calendar-wrapper .calendar-week .calendar-day.weekend {
  background: rgba(0, 0, 0, 0.05);
}

.calendar-wrapper .calendar-week .calendar-day.active {
  background-color: rgba(0, 150, 160, 0.7);
  border-color: #0096a0;
  color: white;
}

.calendar-wrapper .calendar-week .calendar-day.active:hover {
  background-color: rgba(0, 150, 160, 0.4);
}

.calendar-wrapper .calendar-week .calendar-day:hover {
  cursor: pointer;
  background-color: rgba(0, 150, 160, 0.15);
}


.rs-input-group .rs-picker-input.rs-picker-tag {
  border: none;
}

.alert {
  padding: 6px 12px;
  font-size: 14px;
  overflow-wrap: anywhere;
}

.alert.alert-success {
  background: rgba(107, 172, 106, 0.4);
  border: 1px solid rgba(140, 190, 139, 0.4);
  color: #40713f;
}

.alert.alert-warning {
  background: rgba(236, 173, 78, 0.4);
  border: 1px solid rgba(241, 194, 124, 0.4);
  color: #bf7b15;
}

.alert.alert-primary {
  background: rgba(83, 215, 255, 0.4);
  border: 1px solid rgba(134, 227, 255, 0.4);
  color: #00b5ec;
}

.alert.alert-secondary {
  background: rgba(218, 108, 208, 0.4);
  border: 1px solid rgba(228, 149, 221, 0.4);
  color: #b32da7;
}

.alert.alert-danger {
  background: rgba(218, 66, 66, 0.4);
  border: 1px solid rgba(226, 109, 109, 0.4);
  color: #981e1e;
}

.topbar {
  position: fixed;
  right: 0;
  transition: 0.2s background-color, 0.2s left;
  color: white !important;
  height: 50px;
  left: 203px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.05);
  top: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 13px;
}

/* .topbar i {
  color: white !important;
} */
/* .topbar i {
  color: #858585;
  transition: 0.2s all;
} */
.topbar i:hover {
  color: #525252;
  cursor: pointer;
}

.topbar .search-bar {
  width: 30%;
  max-width: 400px;
}

.topbar.topbar-fulld {
  left: 0;
  background: white;
  color: #333 !important;
}

.topbar.topbar-fulld i {
  color: #333 !important;
}

.topbar.topbar-full {
  left: 0;
  background: white;
  /* color: #333 !important; */
}

.topbar .module-shortcuts {
  display: flex;
  min-width: 33%;
}

.topbar .module-shortcut {
  height: 50px;
  width: 50px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.topbar .module-shortcut .fa-fai {
  font-size: 40px;
}

.topbar .module-shortcut.comercial {
  transition: 0.2s all;
}

.topbar .module-shortcut.comercial:hover {
  cursor: pointer;
  background: #4caf50;
}

.topbar .module-shortcut.comercial:hover i {
  color: white !important;
}

.topbar .module-shortcut.validacoes {
  transition: 0.2s all;
}

.topbar .module-shortcut.validacoes:hover {
  cursor: pointer;
  background: #ff5733;
}

.topbar .module-shortcut.validacoes:hover i {
  color: white !important;
}

.topbar .module-shortcut.dashboard {
  transition: 0.2s all;
}

.topbar .module-shortcut.dashboard:hover {
  cursor: pointer;
  background: purple;
}

.topbar .module-shortcut.dashboard:hover i {
  color: white !important;
}

.topbar .module-shortcut.compras-estoque {
  transition: 0.2s all;
}

.topbar .module-shortcut.compras-estoque:hover {
  cursor: pointer;
  background: #ff5733;
}

.topbar .module-shortcut.compras-estoque:hover i {
  color: white !important;
}

.topbar .module-shortcut.laboratorio {
  transition: 0.2s all;
}

.topbar .module-shortcut.laboratorio:hover {
  cursor: pointer;
  background: #323233;
}

.topbar .module-shortcut.laboratorio:hover i {
  color: white !important;
}

.topbar .module-shortcut.assistencia-tecnica {
  transition: 0.2s all;
}

.topbar .module-shortcut.assistencia-tecnica:hover {
  cursor: pointer;
  background: #ffb533;
}

.topbar .module-shortcut.assistencia-tecnica:hover i {
  color: white !important;
}

.topbar .module-shortcut.preventiva {
  transition: 0.2s all;
}

.topbar .module-shortcut.preventiva:hover {
  cursor: pointer;
  background: #6a30d1;
}

.topbar .module-shortcut.preventiva:hover i {
  color: white !important;
}

.topbar .module-shortcut.vendas-dibi {
  transition: 0.2s all;
}

.topbar .module-shortcut.vendas-dibi:hover {
  cursor: pointer;
  background: #c836b1;
}

.topbar .module-shortcut.vendas-dibi:hover i {
  color: white !important;
}

.topbar .module-shortcut.configuracoes {
  transition: 0.2s all;
}

.topbar .module-shortcut.configuracoes:hover {
  cursor: pointer;
  background: #36a5eb;
}

.topbar .module-shortcut.configuracoes:hover i {
  color: white !important;
}

.color-picker .color-block {
  height: 18px;
  width: 100%;
  min-width: 50px;
}

.color-picker .rs-btn {
  border: 1px solid #e5e5ea;
}

.block-picker {
  border: 1px solid rgba(51, 51, 51, 0.1);
}

.block-picker>div:nth-child(3)>div:first-child {
  display: flex;
  flex-wrap: wrap;
}

.block-picker>div:nth-child(3)>div:first-child>span>div {
  margin-right: 8px !important;
}

.nec-tree {
  display: flex;
  flex-direction: column;
}

.nec-tree .nec-item {
  min-height: 34px;
  position: relative;
  width: 250px;
  padding: 10px;
}

.nec-tree .nec-item::before {
  position: absolute;
  content: "";
  height: calc(100% - 65px);
  width: 1px;
  background: #e5e5ea;
  top: 34px;
}

.nec-tree .nec-item .nec-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.nec-tree .nec-item .nec-data .nec-item-icon {
  transition: 0.2s all;
}

.nec-tree .nec-item .nec-data .nec-item-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.nec-tree .nec-item-add {
  display: flex;
  justify-content: flex-end;
  margin: 4px 0;
  position: relative;
}

.nec-tree .nec-item-add .rs-input-group {
  width: 100% !important;
}

.nec-tree .nec-children {
  left: 50%;
  position: relative;
  margin-top: 10px;
  background: white;
}

.nec-tree .nec-children .nec-data::before {
  position: absolute;
  content: "";
  top: 50%;
  right: calc(100% + 9px);
  height: 1px;
  width: 115px;
  background: #e5e5ea;
}

.nec-tree .nec-children .nec-item-add::before {
  position: absolute;
  content: "";
  top: 50%;
  right: 100%;
  height: 1px;
  width: 115px;
}

.card {
  width: 100%;
  background: white;
  min-height: 25px;
  border-radius: 4px;
  padding: 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.card .card-header {
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  margin-top: -8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px 8px;
  font-weight: bold;
  color: rgba(51, 51, 51, 0.85);
  letter-spacing: 0.6px;
  font-size: 15px;
}

.card .card-body {
  padding-bottom: 8px;
}

.card .card-footer {
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 2px;
  margin-bottom: -8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px 8px;
  font-weight: bold;
  color: rgba(51, 51, 51, 0.85);
  letter-spacing: 0.6px;
  font-size: 15px;
}

.rs-panel-body .rs-form-group {
  display: flex;
  flex-direction: column;
}

.button {
  padding: 4px 8px;
  font-size: 14px;
  letter-spacing: -0.4px;
}

.button.button-success {
  background: #6bac6a;
  border: 2px solid #6bac6a;
  color: #f2f2f2;
  transition: 0.2s all;
}

.button.button-success:hover {
  cursor: pointer;
  background: rgba(107, 172, 106, 0);
  color: #5ba25a;
}

.button.button-warning {
  background: #ecad4e;
  border: 2px solid #ecad4e;
  color: #f2f2f2;
  transition: 0.2s all;
}

.button.button-warning:hover {
  cursor: pointer;
  background: rgba(236, 173, 78, 0);
  color: #eaa237;
}

.button.button-primary {
  background: #53d7ff;
  border: 2px solid #53d7ff;
  color: #f2f2f2;
  transition: 0.2s all;
}

.button.button-primary:hover {
  cursor: pointer;
  background: rgba(83, 215, 255, 0);
  color: #3ad1ff;
}

.button.button-secondary {
  background: #da6cd0;
  border: 2px solid #da6cd0;
  color: #f2f2f2;
  transition: 0.2s all;
}

.button.button-secondary:hover {
  cursor: pointer;
  background: rgba(218, 108, 208, 0);
  color: #d558c9;
}

.button.button-danger {
  background: #da4242;
  border: 2px solid #da4242;
  color: #f2f2f2;
  transition: 0.2s all;
}

.button.button-danger:hover {
  cursor: pointer;
  background: rgba(218, 66, 66, 0);
  color: #d62d2d;
}

.rs-btn .rs-btn-spin::before {
  border: 3px solid rgba(247, 247, 250, 0.4) !important;
}

.rs-btn .rs-btn-spin::after {
  border-color: #f7f7fa transparent transparent !important;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:hover .rs-btn {
  text-decoration: none;
}

a .rs-btn {
  height: 100%;
  text-decoration: none;
}

a .rs-btn i {
  text-decoration: none;
}

a .rs-btn:hover {
  text-decoration: none;
}

.search-bar {
  position: relative;
  margin: 6px 0;
}

.search-bar label {
  font-size: 13px;
  font-weight: bold;
  color: #525252;
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 1;
  pointer-events: none;
  padding: 2px 8px;
  transition: 0.1s all, background-color 0.01s linear;
}

.search-bar label i {
  font-size: 7px;
  color: #da4242;
}

.search-bar .search-content {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.search-bar .search-content i {
  color: #858585;
  transition: 0.2s all;
}

.search-bar .search-content i:hover {
  color: #525252;
  cursor: pointer;
}

.search-bar .search-content.loading {
  pointer-events: none;
}

.search-bar .search-content.loading input {
  color: #5f5f5f;
}

.search-bar .search-content .search-results {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  background: white;
  top: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  transition: 0.2s all;
  max-height: 100px;
  overflow-y: hidden;
}

.search-bar .search-content .search-results.open {
  opacity: 1;
  z-index: 3;
}

.search-bar .search-content .search-results.focused {
  border: 1px solid rgba(0, 150, 160, 0.7);
  border-top: none;
}

.search-bar .search-content .search-results .item {
  font-size: 14px;
  color: #5f5f5f;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  padding: 4px 8px;
}

.search-bar .search-content .search-results .item.disabled {
  pointer-events: none;
  color: rgba(95, 95, 95, 0.6);
}

.search-bar .search-content .search-results .item:hover {
  background: rgba(0, 150, 160, 0.05);
  cursor: pointer;
}

.search-bar .search-content input {
  transition: 0.2s all, border-bottom-left-radius 0s linear, border-bottom-right-radius 0s linear;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  font-size: 14px;
  padding: 4px 8px;
  padding-right: 27px;
  color: #454545;
  width: 100%;
}

.search-bar .search-content input.open {
  border-radius: 13px 13px 0px 0px;
  border-bottom: none;
}

.search-bar .search-content input:focus {
  border: 1px solid rgba(0, 150, 160, 0.7);
  outline: none;
}

.search-bar .search-content input:focus.open {
  border-bottom: none;
}

.search-bar .search-content i {
  position: absolute;
  right: 8px;
}

.rs-modal-min .rs-modal-body[style] {
  min-height: 300px !important;
}

.rs-modal-min .rs-modal-content {
  padding: 0;
}

.rs-modal-min .rs-modal-content .rs-panel:first-child {
  border: none;
}

.inbox .inbox-header .rs-list-item-content {
  display: flex;
  align-items: center;
}

.inbox .inbox-header .rs-list-item-content div:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 9px;
}

.inbox .inbox-header .rs-list-item-content div:nth-child(1) {
  width: 5%;
}

.inbox .inbox-header .rs-list-item-content div:nth-child(2) {
  width: 20%;
}

.inbox .inbox-header .rs-list-item-content div:nth-child(3) {
  width: 45%;
}

.inbox .inbox-header .rs-list-item-content div:nth-child(4) {
  width: 20%;
}

.inbox .inbox-header .rs-list-item-content div:nth-child(5) {
  width: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.inbox .inbox-message.inbox-not-seen {
  background: rgba(0, 137, 155, 0.15);
}

.inbox .inbox-message:hover {
  cursor: pointer;
  background: rgba(0, 137, 155, 0.05);
}

.inbox .inbox-message:hover.inbox-not-seen {
  background: rgba(0, 137, 155, 0.2);
}

.inbox .inbox-message .rs-list-item-content {
  display: flex;
  align-items: center;
}

.inbox .inbox-message .inbox-message-icon {
  min-width: 5%;
  max-width: 5%;
  margin-left: -9px;
  margin-right: 9px;
}

.inbox .inbox-message .inbox-message-from {
  min-width: 20%;
  max-width: 20%;
  padding-left: 9px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.inbox .inbox-message .inbox-message-text {
  min-width: 45%;
  max-width: 45%;
  padding-left: 9px;
  padding-right: 9px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.inbox .inbox-message .inbox-message-text .inbox-message-title {
  font-weight: bold;
}

.inbox .inbox-message .inbox-message-text .inbox-message-content {
  font-size: 0.8rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #8c8c8c;
}

.inbox .inbox-message .inbox-message-date {
  min-width: 20%;
  max-width: 20%;
  padding-left: 9px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  opacity: 0.8;
}

.inbox .inbox-message .inbox-message-meta {
  min-width: 10%;
  max-width: 10%;
  padding-left: 9px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.inbox .inbox-message .inbox-message-meta .inbox-message-meta-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.inbox .inbox-message .inbox-message-meta .inbox-message-meta-icons i {
  opacity: 0.7;
  margin-right: 5px;
}

.inbox .inbox-read-message .rs-panel-body .rs-divider:first-child {
  margin-top: -10px;
}

.inbox .inbox-read-message .rs-panel-heading {
  padding: 12px;
  padding-bottom: 6px;
}

.inbox .inbox-read-message .inbox-read-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 6px;
}

.inbox .inbox-read-message .inbox-read-header .inbox-read-send {
  width: 50%;
}

.inbox .inbox-read-message .inbox-read-header .inbox-read-send:not(:last-child) .rs-picker-check-tree {
  padding-right: 8px;
}

.inbox .inbox-read-message .inbox-read-header .inbox-read-send .rs-picker-check-tree {
  width: 100%;
}

.inbox .inbox-read-message .inbox-read-header .inbox-read-actions {
  min-width: 20%;
  max-width: 20%;
}

.inbox .inbox-read-message .inbox-read-header .inbox-read-title {
  font-weight: bold;
  min-width: 60%;
  max-width: 60%;
  display: flex;
  justify-content: center;
}

.inbox .inbox-read-message .inbox-read-header .inbox-read-meta {
  color: #8c8c8c;
  min-width: 20%;
  max-width: 20%;
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-end;
}

.chat-container {
  position: fixed;
  right: 2em;
  bottom: calc(2em + 64px + 8px);
  z-index: 1000;
}

.chat-container .chat-bubble {
  position: fixed;
  right: 2em;
  bottom: 2em;
  z-index: 1;
  background: #0096a0;
  padding: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: 0.2s all;
}

.chat-container .chat-bubble:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.chat-container .chat-bubble i {
  color: white;
  font-size: 34px;
}

.chat-container .chat-wrapper {
  background: #0096a0;
  padding: 12px;
  height: 500px;
  position: absolute;
  border-radius: 12px;
  right: 0;
  bottom: 0;
  width: 300px;
}

.chat-container .chat-wrapper .chat-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-container .chat-wrapper .chat-box .chat-header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  min-height: 78px;
}

.chat-container .chat-wrapper .chat-box .chat-header .chat-subtitle {
  font-size: 14px;
}

.chat-container .chat-wrapper .chat-box .chat-input {
  min-height: 48px;
  background: white;
  border-radius: 0px 0 12px 12px;
  display: flex;
}

.chat-container .chat-wrapper .chat-box .chat-input .chat-field {
  width: 100%;
  opacity: 0.4;
  display: flex;
  align-items: center;
  padding: 12px;
}

.chat-container .chat-wrapper .chat-box .chat-input .chat-send {
  display: flex;
  align-items: center;
  font-size: 24px;
  width: 45px;
}

.chat-container .chat-wrapper .chat-box .chat-input .chat-send i {
  opacity: 0.6;
  transition: 0.2s all;
}

.chat-container .chat-wrapper .chat-box .chat-input .chat-send i:hover {
  cursor: pointer;
  opacity: 0.9;
}

.chat-container .chat-wrapper .chat-box .chat-list {
  height: 100%;
  width: 100%;
  background: white;
  border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: column;
  padding: 8px;
  overflow: auto;
  overflow-x: hidden;
}

.chat-container .chat-wrapper .chat-box .chat-list .message-list {
  display: flex;
  flex-direction: column;
}

.chat-container .chat-wrapper .chat-box .chat-list .message-list .message {
  padding: 4px 8px;
  margin-bottom: 8px;
  background: whitesmoke;
  border-radius: 12px;
  color: #555;
}

.chat-container .chat-wrapper .chat-box .chat-list .message-list .message.incoming {
  max-width: 80%;
  float: left;
  background: #dcf5f6;
}

.chat-container .chat-wrapper .chat-box .chat-list .message-list .message.outcoming {
  float: right;
  margin-left: 20%;
}

.breadcrumbs {
  display: flex;
  margin-left: 12px;
}

.breadcrumbs .breadcrumb-item {
  padding-right: 12px;
  position: relative;
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumbs .breadcrumb-item:not(:last-child)::after {
  content: "/";
  position: absolute;
  right: 3px;
  top: 0;
}

.breadcrumbs .breadcrumb-item.has-link:hover {
  cursor: pointer;
  text-shadow: 0 0 2px white;
}

.breadcrumbs .breadcrumb-item.active {
  font-weight: bold;
  pointer-events: none;
}

.easy-access {
  display: flex;
  margin-top: 20px;
}

.easy-access .easy-access-block {
  width: 20%;
  height: 170px;
  border: 4px solid;
  background-color: white;
  border-radius: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  box-shadow: 0px 0px 0px 0px white inset;
  transition: 0.2s all;
}

.easy-access .easy-access-block:hover {
  cursor: pointer;
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.4) inset;
}

.easy-access .easy-access-block .easy-access-icon {
  font-size: 50px;
}

.easy-access .easy-access-block .easy-access-title {
  font-size: 25px;
}

.rs-panel-bordered.checkered {
  border-style: dashed;
  border-color: #ceced8;
}

.rs-panel-bordered.checkered .rs-panel-body {
  padding: 10px;
}

.rs-panel-bordered .filemeta-meta {
  display: flex;
  align-items: center;
  max-width: 85%;
}

.rs-panel-bordered .filemeta-meta .file-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.file-with-meta img {
  width: 100%;
  max-height: 100px;
  object-fit: cover;
}

.slider {
  min-height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.slider .slider-line {
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.slider .slider-line .slider-gradient {
  height: 100%;
  position: relative;
  transform: translateX(calc(-50% + (6.6666666667px)));
  width: 80px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 150, 160, 0.9) 50%, rgba(0, 0, 0, 0) 100%);
}

.slider .slider-bar {
  left: 50px;
  height: 27px;
  width: 13.3333333333px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: relative;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.slider .slider-bar:hover {
  cursor: pointer;
}

.drag-item {
  z-index: 999999;
  list-style: none;
  border: 1px solid #e5e5ea;
  padding: 7px 11px;
  border-radius: 6px;
  margin-bottom: 4px;
  background: #fff;
}

.drag-item:hover {
  cursor: move;
  color: #0096a0;
  border: 1px solid #0096a0;
}

.drag-list {
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  padding: 7px;
  list-style: none;
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
}

.filterable .rs-table-cell {
  display: flex;
  justify-content: space-between;
}

.filterable .rs-table-cell .rs-table-cell-content {
  display: flex;
  justify-content: space-between;
}

.filterable .rs-table-cell .rs-table-cell-content .filterable-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rs-popover .rs-meta {
  color: #0096a0;
  text-align: right;
  font-size: 10px;
  margin-top: 10px;
  transition: 0.2s all;
}

.rs-popover .rs-meta:hover {
  cursor: pointer;
  color: #00aeba;
}

.rs-table.inline .rs-table-row .rs-table-cell-content {
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rs-table.inline .rs-table-row .rs-table-cell-content div:first-of-type {
  width: 100%;
}

.rs-table.inline .rs-table-row .rs-table-cell-content .rs-picker-toggle {
  border-radius: 0;
  width: 100%;
}

.rs-table.inline .rs-table-row .rs-table-cell-content .rs-picker-toggle .rs-picker-toggle-value {
  white-space: nowrap !important;
}

.rs-table.inline .rs-table-row .rs-table-cell-content .rs-input {
  border-radius: 0;
}

.rs-table.inline .rs-table-row .rs-table-cell-content .rs-input-meta {
  display: none;
}

.table-bottom-items {
  width: 100%;
  padding: 20px 20px;
  padding-bottom: 0;
}

.compact .rs-table-cell-last .rs-table-cell-content {
  justify-content: center;
  display: flex;
  align-items: center;
}

.text-success {
  color: #6bac6a !important;
}

.text-warning {
  color: #ecad4e !important;
}

.text-primary {
  color: #53d7ff !important;
}

.text-secondary {
  color: #da6cd0 !important;
}

.text-danger {
  color: #da4242 !important;
}

.text-print {
  color: #7c2b7c !important
}

.justify-content-center {
  justify-content: center;
}

.w-100 {
  width: 100%;
}

.justify-content-between {
  justify-content: space-between;
}

.flex-direction-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.pill-tabs {
  background: none !important;
  margin-bottom: 0 !important;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: gainsboro;
}

::-webkit-scrollbar-thumb {
  background-color: #005664;
  border-radius: 20px;
  border: 3px solid #005664;
}


body,
html {
  scroll-behavior: smooth;
}

.pill-tabs .rs-nav-item {
  background: white;
  border: 1px solid #e5e5ea;
  border-bottom: none;
  margin-right: 6px;
  border-radius: 8px 8px 0 0;
}

.pill-tabs .rs-nav-item.rs-nav-item-active {
  background: #f7f7f7;
}

.pill-tabs.modal-tabs {
  background: transparent !important;
  position: absolute;
  top: -33px;
}

.pill-tabs.modal-tabs .rs-nav-item {
  background: white;
}

.rs-btn-toggle {
  max-width: 44px;
}

.rs-control-label {
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex !important;
}

.rs-navbar-nav .rs-nav-item>.rs-nav-item-content {
  padding: 6px 9px !important;
  height: auto !important;
}

.rs-input-group .rs-btn.rs-btn-default.rs-picker-toggle {
  border: none !important;
}

.checkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkbox .rs-btn-toggle {
  margin-top: 6px;
}

.number-input input {
  display: block;
  width: 100%;
  color: #575757;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e5e5ea;
  transition: border-color ease-in-out 0.3s;
  border-radius: 6px;
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  height: 36px\9;
}

.number-input input:hover,
.number-input input:focus {
  border-color: #181368;
}

.number-input input:focus {
  outline: none;
}

.rs-input-suffix .rs-dropdown {
  height: 100%;
}

.rs-input-suffix .rs-dropdown .rs-btn {
  height: 100%;
  border-radius: 0px 6px 6px 0px;
}

.rs-input-group .rs-input-meta {
  position: absolute;
  right: 0;
  top: 100%;
  font-size: 10px;
  opacity: 0.8;
}

.rs-form-control-wrapper .rs-picker-select,
.rs-form-control-wrapper .rs-picker-countable {
  width: 100%;
}

.rs-form-control-wrapper .rs-picker-select .rs-picker-toggle-value,
.rs-form-control-wrapper .rs-picker-countable .rs-picker-toggle-value {
  white-space: normal !important;
}

.rs-picker-toggle-value {
  padding-right: 30px;
}

.rs-picker-toggle-value .rs-picker-value-list {
  display: flex;
  flex-wrap: wrap;
}

.rs-picker-toggle-value .rs-picker-value-count {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
}

.rs-form-group .rs-picker-date {
  width: 100%;
}

.file-upload-input {
  position: relative;
}

.file-upload-input .rs-uploader-file-items {
  margin-left: 0;
}

.file-upload-input .isImage {
  min-height: 200px;
  margin-left: 200px;
}

.file-upload-input .rs-uploader-file-item {
  margin-bottom: 5px;
}

.file-upload-input .rs-uploader-file-item-icon-wrapper {
  display: none !important;
}

.file-upload-input .file-uploaded {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.file-upload-input .file-uploaded .file-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.file-upload-input .file-uploaded .file-header .file-thumbnail {
  width: 220px;
  transition: 0.2s all;
  top: -9px;
  height: 218px;
  position: absolute;
  left: -36px;
}

.file-upload-input .file-uploaded .file-header .file-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-upload-input .file-uploaded .file-header .file-icon {
  position: absolute;
  left: -28px;
  top: 5px;
}

.file-upload-input .file-uploaded .file-header .file-icon i {
  font-size: 26px;
}

.rs-table-cell-content .rs-checkbox-checker {
  padding: 0;
}

.rs-table-cell-content .rs-checkbox-checker .rs-checkbox-wrapper {
  left: 0;
  top: 0;
}

.file-meta a {
  position: relative;
  z-index: 5;
}

.rdw-focused .rdw-editor-wrapper {
  border: 1px solid #0096a0;
}

.rdw-editor-wrapper {
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  transition: border-color ease-in-out 0.3s;
}

.rdw-editor-wrapper .DraftEditor-editorContainer {
  padding: 10px;
}

.rdw-editor-wrapper .rdw-editor-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5ea;
}

.rdw-editor-wrapper .rdw-editor-toolbar>div {
  display: flex;
}

.rdw-editor-wrapper .rdw-editor-toolbar>div .rdw-option-wrapper {
  padding: 4px 8px;
  padding-bottom: 6px;
  transition: 0.1s all;
}

.rdw-editor-wrapper .rdw-editor-toolbar>div .rdw-option-wrapper.rdw-option-disabled {
  opacity: 0.1;
}

.rdw-editor-wrapper .rdw-editor-toolbar>div .rdw-option-wrapper.rdw-option-active {
  background: #e6e6e6;
}

.rdw-editor-wrapper .rdw-editor-toolbar>div .rdw-option-wrapper.rdw-option-active:hover {
  cursor: pointer;
  background: #f0f0f0;
}

.rdw-editor-wrapper .rdw-editor-toolbar>div .rdw-option-wrapper:not(.rdw-option-disabled):hover {
  cursor: pointer;
  background: #f0f0f0;
}

.rdw-editor-wrapper .rdw-editor-toolbar>div img {
  object-fit: contain;
}

.notification {
  display: flex;
  align-items: center;
  font-size: 17px;
  position: relative;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  margin-bottom: 4px;
  padding: 4px;
  transition: 0.2s all;
}

.notification:hover {
  background: #fafafa;
  cursor: pointer;
}

.notification .notification-text {
  padding-right: 90px;
  max-width: 400px;
}

.notification .notification-icon {
  margin-right: 14px;
}

.notification .notification-meta {
  font-size: 9px;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.6;
}

.rs-popover .rs-loader {
  padding-bottom: 28px;
}

.fa-bell {
  position: relative;
}

.fa-bell .bell-ball {
  position: absolute;
  right: 0;
  top: -50%;
  font-size: 15px;
  color: red;
}

.compact .rs-table-cell-content {
  padding: 2px !important;
  height: 25px !important;
}

.compact .link-group .rs-table-cell-content {
  text-align: center;
}

.compact .rs-table-cell-group-right-shadow {
  box-shadow: 0px 0 5px rgba(9, 9, 9, 0.2);
}

.compact .filterable-header {
  padding-left: 4px;
}

.compact .filterable-header div:nth-child(2) {
  display: none !important;
  background-color: red !important;
}

.diff .diff-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  margin-bottom: 4px;
}

.diff .diff-row .bold {
  font-weight: bold;
}

.diff .diff-row .key {
  width: 33%;
}

.diff .diff-row .column {
  width: 33%;
  text-align: right;
}

.diff .diff-row .old {
  width: 33%;
  word-wrap: break-word;
  text-align: right;
  color: #bb2020;
}

.diff .diff-row .new {
  width: 33%;
  word-wrap: break-word;
  text-align: right;
  color: #27b427;
}

.error .error-title {
  font-size: 23px;
  margin-bottom: 16px;
  text-align: center;
}

.error .error-link {
  text-align: center;
}

.error .error-stack {
  display: flex;
  flex-direction: column;
}

.error .error-stack .error-item {
  margin-bottom: 6px;
  margin-left: 30px;
  color: #999;
}

.error .error-stack .error-item .error-item-text {
  color: #0096a0;
}

.comercial-page .rs-table-row-header {
  border-bottom: 2px solid #0096a0;
}

.comercial-page .rs-table-row-header .rs-table-cell-content {
  font-weight: bold;
  color: #333;
  font-size: 13px;
}

.compras-estoque-page .rs-table-row-header {
  border-bottom: 2px solid #0096a0;
}

.compras-estoque-page .rs-table-row-header .rs-table-cell-content {
  font-weight: bold;
  color: #333;
  font-size: 13px;
}

.laboratorio-page .rs-table-row-header {
  border-bottom: 2px solid #0096a0;
}

.laboratorio-page .rs-table-row-header .rs-table-cell-content {
  font-weight: bold;
  color: #333;
  font-size: 13px;
}

.assistencia-tecnica-page .rs-table-row-header {
  border-bottom: 2px solid #0096a0;
}

.assistencia-tecnica-page .rs-table-row-header .rs-table-cell-content {
  font-weight: bold;
  color: #333;
  font-size: 13px;
}

.preventiva-page .rs-table-row-header {
  border-bottom: 2px solid #0096a0;
}

.preventiva-page .rs-table-row-header .rs-table-cell-content {
  font-weight: bold;
  color: #333;
  font-size: 13px;
}

.vendas-dibi-page .rs-table-row-header {
  border-bottom: 2px solid #0096a0;
}

.vendas-dibi-page .rs-table-row-header .rs-table-cell-content {
  font-weight: bold;
  color: #333;
  font-size: 13px;
}

.configuracoes-page .rs-table-row-header {
  border-bottom: 2px solid #0096a0;
}

.configuracoes-page .rs-table-row-header .rs-table-cell-content {
  font-weight: bold;
  color: #333;
  font-size: 13px;
}

.anim-left-enter {
  opacity: 0;
  left: 40px;
}

.anim-left-enter-done {
  left: 0;
  opacity: 1;
  transition: all 200ms ease-in;
}

.anim-left-enter-active {
  left: 40px;
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.anim-top-enter {
  opacity: 0;
  bottom: 4px;
}

.anim-top-enter-done {
  bottom: 0;
  opacity: 1;
  transition: all 200ms ease-in;
}

.anim-top-enter-active {
  bottom: 4px;
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.container {
  position: relative;
}

.anim-fade-enter {
  opacity: 0;
}

.anim-fade-enter-done {
  opacity: 1;
}

.anim-fade-enter-active {
  opacity: 0;
  transition: all 200ms;
}

.anim-fade-exit {
  opacity: 1;
}

.anim-fade-exit-done {
  opacity: 0;
}

.anim-fade-exit-active {
  opacity: 0;
  transition: all 200ms;
}

.login-page svg {
  position: fixed;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.login-page .background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
}

.login-page .form {
  border-radius: 6px;
  border: 1px solid #e5e5ea;
  background: white;
  width: 40%;
  z-index: 2;
  min-width: 768px;
  min-height: 205px;
  max-width: 768px;
  max-height: 470px;
  display: flex;
  justify-content: space-between;
}

.login-page .form .rs-control-label {
  font-weight: bold;
}

.login-page .form .content.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 2em;
}

.login-page .form .content.right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page .form .content.right img {
  width: 80%;
  object-fit: contain;
  height: 80%;
}

.rs-btn.rs-btn-loading {
  opacity: 0.9;
}

.rs-btn.rs-btn-loading .rs-btn-spin::before {
  border: 3px solid #3d8b40;
}

.rs-btn.rs-btn-loading .rs-btn-spin::after {
  border-color: #80c883 transparent transparent;
}

.side-logo img {
  position: fixed;
  right: 30px;
  top: 66px;
  height: 60px;
}

.modules-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modules-page svg {
  position: fixed;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.modules-page .module-container {
  width: 50%;
  padding: 0px 10px;
}

.modules-page .module-container .module-container-items {
  display: flex;
  justify-content: center;
}

.modules-page .module-container .module-container-title {
  text-align: center;
  padding: 0px 5px;
  color: white;
  font-size: 40px;
  font-weight: bold;
}

.modules-page .block-container {
  width: calc(39vw + 30px);
  display: flex;
  flex-wrap: wrap;
}

.modules-page .block-container.s-2 {
  width: calc(39vw + 30px);
}

.modules-page .block-container.s-2 .module-block {
  width: 19.5vw !important;
  height: 19.5vw !important;
}

.modules-page .block-container .module-block {
  margin: 5px;
  width: 13vw;
  height: 13vw;
  transform: scale(1);
  transition: 0.2s all;
  display: flex;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0 0px 0px black inset;
  position: relative;
  text-align: center;
}

.modules-page .block-container .module-block.no-display {
  opacity: 0 !important;
  pointer-events: none !important;
}

.modules-page .block-container .module-block:hover {
  cursor: pointer;
  transform: scale(1.07);
  box-shadow: 0 0 12px 0px rgba(255, 255, 255, 0.2) inset;
  z-index: 2;
}

.modules-page .block-container .module-block .block-fader {
  position: absolute;
  transition: 0.2s all;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0;
}

.modules-page .block-container .module-block .block-icon {
  color: white;
  font-size: 4vw;
}

.modules-page .block-container .module-block .block-title {
  color: white;
  font-size: 18px;
}

@media screen and (max-height: 700px) {
  .modules-page .block-container {
    width: calc(30vw + 30px);
  }

  .modules-page .block-container .module-block {
    height: 10vw;
    width: 10vw;
  }
}

@media screen and (max-height: 800px) and (min-width: 701px) {
  .modules-page .block-container {
    width: calc(36vw + 30px);
  }

  .modules-page .block-container .module-block {
    height: 12vw;
    width: 12vw;
  }
}

@media screen and (max-height: 1367px) {
  .modules-page .block-container .block-title {
    font-size: 15px;
  }
}

.avatar-container {
  display: flex;
}

.avatar-container .avatar-image-container {
  padding: 1em;
}

.top-line {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0px;
  z-index: 1000;
}

@font-face {
  font-family: "icomoon";
  /* src: url("http://sistema.rotad.com.br//fonts/icomoon.eot"); */
  src: url("https://sistema.rotad.com.br/fonts/icomoon.eot");
  src: url("https://sistema.rotad.com.br/fonts/icomoon.eot") format("embedded-opentype"), url("https://sistema.rotad.com.br/fonts/icomoon.ttf") format("truetype"), url("https://sistema.rotad.com.br/fonts/icomoon.woff") format("woff"), url("https://sistema.rotad.com.br/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.fa-fai {
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-fai.icon-hand-truck:before {
  content: "\e900";
}

.fa-fai.icon-packages:before {
  content: "\e901";
}

.fa-fai.icon-cog:before {
  content: "\e902";
}

.fa-fai.icon-wrench:before {
  content: "\e903";
}

.fa-fai.icon-connect:before {
  content: "\e904";
}

.fa-fai.icon-pin:before {
  content: "\e905";
}

.fa-fai.icon-move:before {
  content: "\e906";
}

.fa-fai.icon-file:before {
  content: "\e907";
}

.fa-fai.icon-crm:before {
  content: "\e908";
}

.fa-fai.icon-config:before {
  content: "\e909";
}

.equalizer>div:first-of-type {
  width: 100%;
  height: 100%;
}

fieldset {
  border: none;
}

h4,
h5 {
  display: flex;
}

h4 div,
h5 div {
  overflow: visible;
  margin-left: 4px;
}

.click:hover {
  cursor: pointer;
}

.wrap-span span {
  display: flex;
  flex-direction: column;
}

.rs-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rs-message-body {
  word-break: break-all;
}

.rs-scrollable {
  max-height: 200px;
  overflow-y: auto;
}

.w-100 {
  width: 100%;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

body {
  background: #f5f6fb !important;
}

* {
  font-family: "Open Sans", sans-serif;
}

.rs-panel .rs-table-pagination-toolbar {
  background: white;
}

.m-0 {
  margin: 0 !important;
}

.rs-no-body {
  background: white;
}

.rs-no-body .rs-panel-body {
  padding: 10px 15px !important;
}

.clickable {
  transform: scale(1);
  opacity: 0.8;
  transition: 0.2s all;
}

.clickable:hover {
  cursor: pointer;
  opacity: 1;
  transform: scale(1);
}

.row .rs-form-group {
  min-height: 84px;
}

.row .rs-form-group .rs-picker-select {
  width: 100%;
}

.row .rs-form-group .rs-input,
.row .rs-form-group .rs-input-group {
  max-width: 100%;
}

.row .rs-form-control-wrapper {
  width: 100%;
}

.row .rs-form-control-wrapper .rs-input-group {
  width: 100% !important;
}

.row .rs-input-group-addon .fa-times {
  color: #c53838;
  opacity: 0.8;
}

.row .rs-input-group-addon .fa-check {
  color: #3dc538;
  opacity: 0.8;
}

.d-flex {
  display: flex;
}

.justify-contents-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.mt-1 {
  margin-top: 2.5px;
}

.ml-1 {
  margin-left: 2.5px;
}

.mb-1 {
  margin-bottom: 2.5px;
}

.mr-1 {
  margin-right: 2.5px;
}

.o-1 {
  opacity: 0.9;
}

.p-1 {
  padding: 2.5px;
}

.mb-i-1 {
  margin-bottom: 2.5px !important;
}

.mt-2 {
  margin-top: 5px;
}

.ml-2 {
  margin-left: 5px;
}

.mb-2 {
  margin-bottom: 5px;
}

.mr-2 {
  margin-right: 5px;
}

.mr-46 {
  margin-right: 46%;
}

.color-red {
  color: red;
}

.o-2 {
  opacity: 0.8;
}

.p-2 {
  padding: 5px;
}

.mb-i-2 {
  margin-bottom: 5px !important;
}

.mt-3 {
  margin-top: 7.5px;
}

.ml-3 {
  margin-left: 7.5px;
}

.mb-3 {
  margin-bottom: 7.5px;
}

.mr-3 {
  margin-right: 7.5px;
}

.o-3 {
  opacity: 0.7;
}

.p-3 {
  padding: 7.5px;
}

.mb-i-3 {
  margin-bottom: 7.5px !important;
}

.mt-4 {
  margin-top: 10px;
}

.ml-4 {
  margin-left: 10px;
}

.mb-4 {
  margin-bottom: 10px;
}

.mr-4 {
  margin-right: 10px;
}

.o-4 {
  opacity: 0.6;
}

.p-4 {
  padding: 10px;
}

.mb-i-4 {
  margin-bottom: 10px !important;
}

.mt-5 {
  margin-top: 12.5px;
}

.ml-5 {
  margin-left: 12.5px;
}

.mb-5 {
  margin-bottom: 12.5px;
}

.mr-5 {
  margin-right: 12.5px;
}

.o-5 {
  opacity: 0.5;
}

.p-5 {
  padding: 12.5px;
}

.mb-i-5 {
  margin-bottom: 12.5px !important;
}

.mt-6 {
  margin-top: 15px;
}

.ml-6 {
  margin-left: 15px;
}

.mb-6 {
  margin-bottom: 15px;
}

.mr-6 {
  margin-right: 15px;
}

.o-6 {
  opacity: 0.4;
}

.p-6 {
  padding: 15px;
}

.mb-i-6 {
  margin-bottom: 15px !important;
}

.mt-7 {
  margin-top: 17.5px;
}

.ml-7 {
  margin-left: 17.5px;
}

.mb-7 {
  margin-bottom: 17.5px;
}

.mr-7 {
  margin-right: 17.5px;
}

.o-7 {
  opacity: 0.3;
}

.p-7 {
  padding: 17.5px;
}

.mb-i-7 {
  margin-bottom: 17.5px !important;
}

.mt-8 {
  margin-top: 20px;
}

.ml-8 {
  margin-left: 20px;
}

.mb-8 {
  margin-bottom: 20px;
}

.mr-8 {
  margin-right: 20px;
}

.o-8 {
  opacity: 0.2;
}

.p-8 {
  padding: 20px;
}

.mb-i-8 {
  margin-bottom: 20px !important;
}

.mt-9 {
  margin-top: 22.5px;
}

.ml-9 {
  margin-left: 22.5px;
}

.mb-9 {
  margin-bottom: 22.5px;
}

.mr-9 {
  margin-right: 22.5px;
}

.o-9 {
  opacity: 0.1;
}

.p-9 {
  padding: 22.5px;
}

.mb-i-9 {
  margin-bottom: 22.5px !important;
}

.mt-10 {
  margin-top: 25px;
}

.ml-10 {
  margin-left: 25px;
}

.mb-10 {
  margin-bottom: 25px;
}

.mr-10 {
  margin-right: 25px;
}

.o-10 {
  opacity: 0;
}

.p-10 {
  padding: 25px;
}

.mb-i-10 {
  margin-bottom: 25px !important;
}

.mt-11 {
  margin-top: 27.5px;
}

.ml-11 {
  margin-left: 27.5px;
}

.mb-11 {
  margin-bottom: 27.5px;
}

.mr-11 {
  margin-right: 27.5px;
}

.o-11 {
  opacity: -0.1;
}

.p-11 {
  padding: 27.5px;
}

.mb-i-11 {
  margin-bottom: 27.5px !important;
}

.mt-12 {
  margin-top: 30px;
}

.ml-12 {
  margin-left: 30px;
}

.mb-12 {
  margin-bottom: 30px;
}

.mr-12 {
  margin-right: 30px;
}

.o-12 {
  opacity: -0.2;
}

.p-12 {
  padding: 30px;
}

.mb-i-12 {
  margin-bottom: 30px !important;
}

.rs-form-control-wrapper .rs-picker-check-tree {
  width: 100%;
}

.rs-picker-toggle-value {
  max-height: 60px;
}

.rs-picker-disabled {
  opacity: 1 !important;
}

.rs-no-body.on-top .rs-input {
  background: white !important;
  color: #575757;
  cursor: text;
}

.highlight {
  color: black;
  background-color: yellow;
  padding: 2px;
}

.rs-list-item-content .rs-form-group {
  min-height: auto;
}

.rs-no-body.allow-overflow.w-100.rs-panel .rs-panel-body {
  width: 100%;
  padding: 0 16px !important;
}

.rdw-editor-wrapper {
  /* height: 100px !important; */
  overflow-y: auto !important;
  word-break: break-all;
}


.logged{
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
    width: 20dvw;
}