* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header {
    width: 100%;
    height: 6dvh;
    background-color: #00A0BD;
    padding: 0 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.container {
    margin-bottom: 1rem;
}

.userInformations {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.userName {
    font-size: 1rem;
    color: #fff;
}

.arrow {
    width: 32px;
    height: 32px;
    color: #fff;
}

.mainContainer {
    width: 90%;
    margin: 0 auto;
    margin-top: 1rem;
}

.formContainer {
    width: 100%;
}

.containerText {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 0;
}

.containerText2 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // padding: .5rem 0;
}

.form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.cardText {
    display: flex;
    flex-direction: column;
    gap: .3rem;
}

.reloadButton {
    font-size: 1.1rem;
    font-weight: bold;
}

.registerVehicleText {
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
}

.customInputType {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    input {
        margin-left: 1rem;
    }
}

.containerToolBar {
    width: 100%;
}

.searchPlateContainer {
    width: 100%;
    display: flex;
    gap: 1rem;

    button {
        font-size: .5rem;
    }
}

.returnPageText {
    color: #FFF;
    margin-left: -.5rem;
}

.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
