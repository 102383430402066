.map-container {
    margin-top: 10px;
    width: 100%;
    height: 350px;
    z-index: 2;
}

.title-info-map {
    margin-top: -5px;
}

.geolocation-address {
    /* margin-top: 3px; */
    width: 100%;
    height: 40px;
    align-items: center;
    padding-left: 10px;
    border-radius: 5px;
    display: flex;
    background-color: rgba(0, 76, 175, 0.215);
}

a {
    display: inline-block;
    text-decoration: none !important;
    color: #042446;
    border-radius: 5px;
    font-weight: bold;
    transition: all 0.3s ease;
}

.imgLegend {
    display: flex;
    width: 100%;
    height: 20px;
}

.label {
    font-size: 14px;
    color: #575757;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}

.font-12 {
    font-size: 12px;
}