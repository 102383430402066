* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

.container {
    width: 75%;
    height: 100dvh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.containerForm {
    margin-top: 30px;
    width: 100%;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.logo {
    width: 90%;
}

.btnSend {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
}
