.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    margin-bottom: -1rem;
}

.labelText {
    font-size: 14.9px;
    font-weight: 500;
}